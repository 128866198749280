<!-- 批量导入新增 -->
<template>
    <div class="AddBatchImport">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">批量导入新增</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" :rules="rules" class="form" inline label-width="150px">
            <el-form-item label="姓名" prop="name">
                <el-input v-model="form.name"  placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="拼音名" >
                <el-input v-model="form.pinyin"  placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="性别" >
                <el-input v-model="form.sex"  placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="出生日期" >
                <el-date-picker
                      v-model="form.birthday"
                      type="date"
                      placeholder="选择日期">
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="国籍" >
                <el-input  v-model="form.nationality" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="民族" >
                <el-input  v-model="form.nation" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="报考学科" prop="subject">
                <el-input  v-model="form.subject" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="原等级" >
                <el-input v-model="form.grade"  placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="报考等级" prop="testGrade">
                <el-input  v-model="form.testGrade" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="移动电话" prop="telephone">
                <el-input v-model="form.telephone"  placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="证件号" prop="IdNumber">
                <el-input v-model="form.IdNumber"  placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="是否有副证" >
                <el-input  v-model="form.whether" placeholder="请输入"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">
                提 交
            </el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    name:"",//姓名
                    pinyin:"",//拼音名
                    sex:"",//性别
                    birthday:"",//出生日期
                    nationality:"",//国籍
                    nation:"",//民族
                    subject:"",//报考学科
                    grade:"",//原等级
                    testGrade:"",//报考等级
                    telephone:"",//移动电话
                    IdNumber:"",//证件号
                    whether:""//是否有副证   
                },
                rules: {
                    name: [
                        {required: true, message: '姓名不能为空',trigger: 'blur'},
                    ],
                    subject: [
                        {required: true, message: '报考学科不能为空',trigger: 'blur'},
                    ],
                    testGrade:[
                        {required: true, message: '报考等级不能为空',trigger: 'blur'},
                    ],
                    telephone: [
                        {required: true, message: '移动电话不能为空',trigger: 'blur'},
                    ],
                    IdNumber:[
                        {required: true, message: '证件号不能为空',trigger: 'blur'},
                    ]
                }
            }
        },
        created() {

        },
        methods: {
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.form)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                this.$router.go(-1);
                this.$refs[form].resetFields();
            },
        }
    }
</script>

<style scoped="scoped">
    .AddBatchImport {
        width: 100%;
        height: 100%;
    }

    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }

    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }

    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        margin: 30px auto;
    }

    ::v-deep .el-form-item__label {
        color: #000000;
    }

    .el-input {
        width: 300px;
        height: 37px !important;
    }

    .el-select {
        width: 300px;
    }

    ::v-deep .el-input.is-disabled .el-input__inner {
        background-color: #FFFFFF;
    }

    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }

    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
